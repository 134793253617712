<template>
  <div class="relative p-normal pb-14">
    <Logo></Logo>
    <a-scroll />
    <div class="politics">
      <h1 class="privacy_tittle">Política de Cookies</h1>
      <div class="politics-terms-container" >
        <small>Última actualización: 31 de mayo de 2021</small>
        <h2>Sumario</h2>
        <ul>
            <li>
                Cookies usadas: <b>Propias y de Terceros</b>.
            </li>
            <li>
                ¿Se usan para obtener estadísticas? <b>Sí, siendo la base legal nuestro interés legítimo en realizar una mejor gestión del servicio</b>.
            </li>
        </ul>
        <h2>Cómo configurar y deshabilitar las cookies</h2>
        Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:<br><br>
        <ul>
            <li>
                <a class="text-primary" href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Edge</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Google Chrome</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=1-636664625754483085-1648636281&rd=1&co=GENIE.Platform%3DAndroid&oco=1" target="_blank">Google Android</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer 8</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank">Internet Explorer 9</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10" target="_blank">Internet Explorer 11</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es" target="_blank">Mozilla Firefox</a>
            </li>
            <li>
                <a class="text-primary" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.apple.com/kb/ph21411?locale=es_ES" target="_blank">Safari</a>
            </li>
            <li>
                <a class="text-primary" href="https://support.apple.com/es-es/HT201265" target="_blank">Safari Mobile</a>
            </li>
        </ul>
        <ol>
            <li>
                <h2>- Tipos y usos</h2>
                El servicio usa cookies propias y de terceros para mejorar la navegación, proporcionar el servicio y obtener estadísticas sobre el uso de la página web
            </li>
            <li>
                <h2>- ¿Qué es una cookie y para qué sirve?</h2>
                Una cookie es un fichero de texto que se envía al navegador web de tu ordenador, móvil o tablet y que sirve para almacenar y recuperar información sobre la navegación realizada. Por ejemplo, recordar tu nombre de usuario y contraseña o las preferencias de tu perfil.
            </li>
            <li>
                <h2>- ¿Qué tipos de cookies hay?</h2>
                Según quién las gestiona:<br><br>
                <ul>
                    <li>
                        <span class="underline_txt">Cookies propias</span>; se envían a tu navegador desde nuestros equipos o dominios web.
                    </li>
                    <li>
                        <span class="underline_txt">Cookies de terceros</span>; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google.
                    </li>
                </ul>
                En función del tiempo que están activas, hay:
                <ul>
                    <li>
                        <span class="underline_txt">Cookies de sesión</span>; permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web, de modo que ninguna queda en tu dispositivo.
                    </li>
                    <li>
                        <span class="underline_txt">Cookies persistentes</span>; permanecen en tu dispositivo y nuestra web las lee cada vez que realizas una nueva visita. Estas cookies dejan de funcionar pasada una fecha concreta.
                    </li>
                </ul>
                Finalmente, y según su finalidad, tenemos:
                <ul>
                    <li>
                        <span class="underline_txt">Cookies técnicas</span>; mejoran la navegación y el buen funcionamiento de la web.
                    </li>
                    <li>
                        <span class="underline_txt">Cookies de personalización</span>; permiten acceder al servicio con unas características predefinidas en función de una serie de criterios.
                    </li>
                    <li>
                        <span class="underline_txt">Cookies de análisis</span>; permiten medir y analizar estadísticamente el uso que se hace del servicio prestado.
                    </li>
                </ul>
                Cookies usadas por <b>SoloFoodies</b>:
                <div class="mask_table">
                    <div class="mask_card">
                        <div class="mask_tr">
                            <b>_ga</b>
                        </div>
                        <div class="mask_tr">
                            Origen: .google.com
                        </div>
                        <div class="mask_tr">
                            Finalidad: Analítica (rendimiento
                        </div>
                        <div class="mask_tr">
                            tipo: Tercero
                        </div>
                        <div class="mask_tr">
                            Caduca en 2 años
                        </div>
                    </div>
                    <div class="mask_card">
                        <div class="mask_tr">
                           <b> _gat</b>
                        </div>
                        <div class="mask_tr">
                            Origen: .google.com
                        </div>
                        <div class="mask_tr">
                            Finalidad: Analítica
                        </div>
                        <div class="mask_tr">
                            tipo: Tercero
                        </div>
                        <div class="mask_tr">
                           Caduca por sesión
                        </div>
                    </div>
                    <div class="mask_card">
                        <div class="mask_tr">
                           <b>_gid</b>
                        </div>
                        <div class="mask_tr">
                           Origen: .google.com
                        </div>
                        <div class="mask_tr">
                            Finalidad: Analítica (funcionalidad)
                        </div>
                        <div class="mask_tr">
                            tipo: Tercero
                        </div>
                        <div class="mask_tr">
                            Caduca en 1 día
                        </div>
                    </div>
                </div>
                Lista de cookies de tercero usadas por  <b>SoloFoodies</b>:
                <br><br>
                <a class="text-primary" href="https://policies.google.com/privacy" target="_blank">Google</a>: Sus cookies (NID o CONSENT) permiten personalizar cómo se ven anuncios fuera de Google o almacenar información como el idioma preferido a la hora de mostrar resultados de búsqueda.
                <br><br>
                <span class="underline_txt">Más información</span> sobre los tipos de cookies que usa Google en sus múltiples servicios.
                <a class="text-primary" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank">Google Analytics</a>: Sus cookies (como por ejemplo _ga) permiten contabilizar las veces que un usuario visita una web, cómo, cuándo y desde dónde lo hace.
                <br><br>
                En cualquier caso, puedes inhabilitar las cookies de Google Analytics desde <a class="text-primary" href="https://tools.google.com/dlpage/gaoptout" target="_blank">aquí</a>.
            </li>
        </ol>
      </div>
    </div>
  </div>
<!--  <Footer></Footer>-->
</template>

<script>
import Logo from '@/components/Logo'
// import Footer from '@/components/Footer'

export default {
  name: 'cookies-politics',
  components: {
    // Footer,
    Logo
  }
}
</script>

<style lang="scss">
    .underline_txt{
        text-decoration: underline;
    }
    .mask_card{
        /*border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;*/
        border: 1px solid;
        max-width: 300px;
        border-radius: 3px;
        padding: 10px;
        @media screen and (min-width: 601px) {
            display: inline-block;
            margin: 10px;
            width: 30%;
        }
        @media screen and (max-width: 600px) {
            display: block;
            margin: 10px auto;
            width: 100%;
        }
    }
    .mask_table{
        text-align: center;
        .mask_tr{
            //border-bottom: 1px solid;
        }
    }
</style>
